<template>
  <b-form-datepicker
    :id="id"
    v-model="$attrs.value"
    v-bind="$attrs"
    today-button
    close-button
    :start-weekday="startWeekday"
    :locale="isLocale"
    :label-today="$t('datepicker.today')"
    :label-no-date-selected="$t('datepicker.noDateSelected')"
    :label-close-button="$t('table.close')"
    :label-help="$t('datepicker.help')"
    :label-current-month="$t('datepicker.currentMonth')"
    :label-next-month="$t('datepicker.nextMonth')"
    :label-next-year="$t('datepicker.nextYear')"
    :label-prev-month="$t('datepicker.prevMonth')"
    :label-prev-year="$t('datepicker.prevYear')"
    :label-today-button="$t('datepicker.todayButton')"
    :label-reset-button="$t('datepicker.resetButton')"
    :dark="isThemeHeader === 'true'"
    v-on="$listeners"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormDatepicker } from 'bootstrap-vue';

export default {
  name: 'DatepickerWrapper',
  components: { BFormDatepicker },
  props: {
    // for proper work in form-group
    id: {
      type: [Number, String],
      default: undefined,
    },
  },
  data() {
    return {
      startWeekday: process.env.VUE_APP_START_WEEK_DAY ? +process.env.VUE_APP_START_WEEK_DAY : 1, //default 1 for Monday
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
    }),
  },
};
</script>